<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <svg
    id="ecvWaveCustomer"
    class="ecvWaveCustomer"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1920 30"
  >
    <path
      id="Path_602"
      data-name="Path 602"
      class="cls-1"
      d="M1920,0H0V15C164,2.05,447.73,0,586.76,0s597.15,30,852.73,30S1920,14.51,1920,14.51Z"
      transform="translate(0 0)"
    />
  </svg>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
</script>
